/* 公共 JS 方法 */
import { apiKefuQq } from '@/api/modules/cad_api';
/*  --- 点击 QQ 客服 Start ---  */
//用户端当前时间,当前星期几
var $curTime,$curDay;

function getTime() {
    //获取用户端当前时间
    $curTime = new Date().getHours();
    //获取用户端当前星期几
    $curDay = new Date().getDay();
}

function get_kefu_qq(type) {
    return new Promise((resolve, reject) => {
        apiKefuQq({ type }).then(res => {
            if (res) resolve(res)
        })
    })
}

//模拟a标签点击，防止被浏览器阻止弹窗，效果同window.open()
function winBlankOpen(url) {
    var body = document.body;
    let aLink = document.createElement('div');
    aLink.innerHTML = '<a target="_blank" rel="noopener noreferrer" href='+url+' id="j_winBlankOpen"></a>';
    body.appendChild(aLink);
    var j_winBlankOpen = document.getElementById('j_winBlankOpen');
    j_winBlankOpen.click();
    aLink.remove();
}

async function openQq(type) {
    var qq_array = await get_kefu_qq(type);
    var qq_array_len = qq_array.length;

    if (qq_array_len <= 0) { return; }

    var curHour = new Date().getHours();
    var curMinu = new Date().getMinutes();
    var curAllTime = curHour*60+curMinu;

    var qq_index = parseInt(curAllTime/5)%qq_array_len;

    winBlankOpen('http://wpa.qq.com/msgrd?v=3&uin='+ qq_array[qq_index] +'&site=qq&menu=yes');
}

function customerService(type) {

    getTime();
    if ($curTime<9||$curTime>18) {
        return 'underline';
    }
    else{
        openQq(type)
    }
}

// 使用 dom 中 SubNav.vue 渲染的联系客服按钮
function useExistQqServe() {
    let subNav = document.querySelector('.sub-nav');
    let subQqServe = subNav.querySelector('.icon-toServ');
    subQqServe.click();
}

// 点击固定 QQ 号客服
function contactService(str) {
    getTime();

    if($curDay<1||$curDay>5||$curTime<10||$curTime>18){
        return 'underline';
    }
    else{
        // // 研发客服qq下线
        // // var qq_array = [3086407570,1085989558,1969755200,292953016,481323996,2139134941,490945475,3205675254,2685698114];
        // var qq_array;
        // if (str == 'company') {
        //     qq_array = [3034386506,481323996,490945475,3034386506,481323996,490945475,3034386506,481323996,490945475];
        // } else if (str == 'wisdomyl') {
        //     qq_array = [3034386506,481323996,2139134941,3034386506,481323996,2139134941,3034386506,481323996,2139134941];
        // }
        // //获取当前时间对应的qq号序号
        // var curHour = new Date().getHours();
        // var curMinu = new Date().getMinutes();
        // var curAllTime = curHour*60+curMinu;
        // var qq_cishu = parseInt(curAllTime/45);
        // var qq_yushu = curAllTime-45*qq_cishu;
        // var qq_xuhao = parseInt(qq_yushu/5);
        // // 跳转至当前qq号对应的链接
        // winBlankOpen('http://wpa.qq.com/msgrd?v=3&uin='+ qq_array[qq_xuhao] +'&site=qq&menu=yes');
        winBlankOpen('http://wpa.qq.com/msgrd?v=3&uin=3034386506&site=qq&menu=yes');
    }
}
/*  --- 点击 QQ 客服 End ---  */

// 使用 dom 中 Header.vue 渲染的登录按钮
function useExistLoginBtn() {
    let headerBox = document.querySelector('.header');
    let login = headerBox.querySelector('.login');
    if (!login) return
    login.click();
}

// 下载图片
function downloadImg(url, name) {
    let image = new Image()
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url;
    image.onload = () => {
        let canvas = document.createElement('canvas')
        canvas.width = image.width
        canvas.height = image.height
        let ctx = canvas.getContext('2d')
        ctx.drawImage(image, 0, 0, image.width, image.height)
        canvas.toBlob((blob) => {
            let url = URL.createObjectURL(blob)
            createLinkDown(url, name)
            // 用完释放URL对象
            URL.revokeObjectURL(url)
        })
    }
}

function createLinkDown(href, name) {
    let body = document.body;
    let aLink = document.createElement('div');
    aLink.innerHTML = '<a target="_blank" rel="noopener noreferrer" href='+href+' download='+name+' id="link-down-img"></a>';
    body.appendChild(aLink);
    let linkDownImg = document.getElementById('link-down-img');
    linkDownImg.click();
    aLink.remove();
}

/* --- 个人中心 cad 获取红包 Start --- */
import { apiGetDiscount } from '@/api/modules/cad_api';
import store from '@/store';
function getDiscountInfo(token) {
    let token_val = token || '';
    // 红包信息
    apiGetDiscount({
      'client': 'web',
      'usertoken': token_val,
    }).then(res => {
        store.commit('cad/setCadDiscount', res);
    })
}
/* --- 个人中心 cad 获取红包 End --- */

/**
 * @description 用户头像可选择
 * id： -1 时，表示用户头像不为以下数据里的
 */
export const avatars = [
    { id: '1', src: 'https://ft.appol.com/avatar/user_head1.png' },
    { id: '2', src: 'https://ft.appol.com/avatar/user_head2.png' },
    { id: '3', src: 'https://ft.appol.com/avatar/user_head3.png' },
    { id: '4', src: 'https://ft.appol.com/avatar/user_head4.png' },
    { id: '5', src: 'https://ft.appol.com/avatar/user_head5.png' },
    { id: '6', src: 'https://ft.appol.com/avatar/user_head6.png' },
    { id: '7', src: 'https://ft.appol.com/avatar/user_head7.png' },
]

/* 导出 */
export default {
    winBlankOpen, 
    customerService,
    contactService,
    useExistQqServe,
    useExistLoginBtn,
    downloadImg,
    getDiscountInfo
}