<template>
  <router-view />
</template>

<script setup>
import { apiUseTokenLogin } from '@/api/modules/cad_api'
import { apiCompanyUseTokenLogin } from '@/api/modules/company_api'
import { apiWisdomylUseTokenLogin } from '@/api/modules/wisdomyl_api'
import { apiBimUseTokenLogin } from '@/api/modules/bim_api'
import { apiGetUserInfo } from '@/api/modules/maple_api'
import { useMapState } from '@/assets/js/use_storemap'
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import globalfun from '@/assets/js/globalfun'

/* --- 全局变量 --- */
// vuex
const store = useStore()
const { userToken, mapleUserInfo } = useMapState({
  userToken: (state) => state.user.token,
  mapleUserInfo: (state) => state.maple.maple_user_info,
})
// router
const router = useRouter()

const userInfoTime = store.getters.getUserInfoTime
const mapleLoginTime = store.getters['maple/getMapleLoginTime']

var allPathParams = ref('') // 路由的全部信息
var nowPath = ref()
var interval_time = 1 // 离上次打开网页的时间

/* --- 函数方法 --- */
router.beforeEach((to, from, next) => {
  allPathParams.value = to
  nowPath.value = to.matched[0].path
  next()
})

// === 个人中心 ===
function cadTokneLogin() {
  if (allPathParams.value.path == '/cad/autologin') return

  if (userInfoTime) {
    apiUseTokenLogin({
      user_token: userToken.value,
    }).then((res) => {
      if (res.code == 1) {
        setInfo(res, 'cad')
      } else {
        clearInfo('/cad')
      }
    })
  } else {
    clearInfo('/cad')
    store.commit('cdkey/setCadKnowExplain', false)
  }
}
function getDiscountInfo() {
  globalfun.getDiscountInfo(userToken.value)
}

// === 企业版 ===
function companyTokenLogin() {
  if (allPathParams.value.path == '/company/autologin') return

  if (userInfoTime) {
    apiCompanyUseTokenLogin({
      user_token: userToken.value,
    }).then((res) => {
      if (res.code == 1) {
        setInfo(res, 'company')
      } else {
        clearInfo('/company/home')
      }
    })
  } else {
    clearInfo('/company/home')
    store.commit('cdkey/setCompanyKnowExplain', false)
  }
}

// === CAD智绘园林 ===
function wisdomylTokenLogin() {
  if (allPathParams.value.path == '/wisdomyl/autologin') return

  if (userInfoTime) {
    apiWisdomylUseTokenLogin({
      user_token: userToken.value,
    }).then((res) => {
      if (res.code == 1) {
        setInfo(res, 'wisdomyl')
      } else {
        clearInfo('/wisdomyl/home')
      }
    })
  } else {
    clearInfo('/wisdomyl/home')
    store.commit('cdkey/setWisdomylKnowExplain', false)
  }
}

// === BIM迷你看图 ===
function bimTokenLogin() {
  if (userInfoTime) {
    apiBimUseTokenLogin({
      user_token: userToken.value,
    }).then((res) => {
      if (res.code == 1) {
        setInfo(res)
      } else {
        clearInfo('/bim/home')
      }
    })
  } else {
    clearInfo('/bim/home')
  }
}

// === Maple中文学生版账号 ===
function mapleCheckLogin() {
  if (mapleLoginTime) {
    apiGetUserInfo({
      uid: mapleUserInfo.value.uid,
    }).then((res) => {
      if (res.code == 1) {
        store.commit('maple/setMapleUserInfo', res.param1)
        store.commit('maple/setMapleLoginTime', new Date().getTime())
      }
    })
  } else {
    store.commit('maple/clearThisAll')
  }
}

function setInfo(res, type) {
  if (res.code == 1) {
    store.commit('setUserInfo', res.param1)
    store.commit('setIsLogin', true)
    store.commit('user/setUserName', res.param1.user_info.account)
    let param = res.param1
    if (param) {
      let cdk = param.vip_info.vip.data.cdk
      if (cdk) store.commit('user/setOfflineCode', { name: type, code: cdk })
    }
  }
}

function clearInfo(link) {
  store.commit('setUserInfo', '')
  store.commit('user/setClearAll')
  store.commit('setIsLogin', false)
  router.push({ path: link })
}

/* --- 监听 --- */
watch(
  () => nowPath.value,
  (newVal, oldVal) => {
    if (userToken.value) {
      // 路由发生变化修改页面的自动登录
      if (newVal == '/company') {
        companyTokenLogin()
      } else if (newVal == '/cad' || newVal == '/activity') {
        cadTokneLogin()
      } else if (newVal == '/wisdomyl') {
        wisdomylTokenLogin()
      } else if (newVal == '/bim') {
        bimTokenLogin()
      }
    }

    if (newVal == '/cad') {
      getDiscountInfo()
    } else if (newVal == '/maple') {
      mapleCheckLogin()
    }
  }
)
</script>

<style lang="less"></style>
