import { get, post } from '@/api/server';

// 获取红包信息
export function apiGetDiscount(data) {
    return post({
        url: '/coupon/getdiscount', 
        data
    })
}

// 获取客服 QQ
export function apiKefuQq(data) {
    return post({
        url: '/qq/get_kefu_qq', 
        data
    })
}

// 获取手机验证码
export function apiGetPhoneCode(data) {
    return post({
        url: '/webcad/sendmsg', 
        data
    })
}

// === 我的账户 ===
// 账户信息 -> 修改头像
export function apiUpAvatar(data) {
    return post({
        url: '/webcad/upavatar', 
        data
    })
}

// 账户信息 -> 修改
export function apiChangeUserInfo(data) {
    return post({
        url: '/webcad/updateuserinfo', 
        data
    })
}

// 账户信息 -> 离线申请码
export function apiGetLeaveCode(data) {
    return post({
        url: '/webcad/getNxi', 
        data
    })
}

// 注销账户 -> 确认注销
export function apiConfirmCancelled(data) {
    return post({
        url: '/webcad/usercannel', 
        data
    })
}

// 修改密码 -> 获取图片验证码
export function apiImgCode(data) {
    return post({
        url: '/common/codenew.php',
        data
    })
}

// 修改密码 -> 验证验证码
export function apiCodeVerification(data) {
    return post({
        url: '/webcad/codeverification',
        data
    })
}

// 修改密码 -> 验证新密码
export function apiCheckNewPwd(data) {
    return post({
        url: '/webcad/updatepassworld',
        data
    })
}

// 绑定微信 -> 绑定微信手机验证码检测
export function apiBindWxCodeCheck(data) {
    return post({
        url: '/webcad/verifyphone',
        data
    })
}

// 绑定微信 -> 扫码后的回调
export function apiScanWxQaBack(data) {
    return post({
        url: '/webcad/verifybindweixin',
        data
    })
}

// 绑定微信 -> 强制绑定
export function apiForceBindWx(data) {
    return post({
        url: '/webcad/forcebind',
        data
    })
}

// 绑定微信 -> 解除绑定
export function apiRelieveWxBind(data) {
    return post({
        url: '/webcad/cannel_weixin_bind',
        data
    })
}

// === 我的VIP ===
// 购买记录
export function apiGetpurchaseRecord(data) {
    return post({
        url: '/webcad/getorders',
        data
    })
}

// 开具发票 -> 获取列表
export function apiGetOrderskplist(data) {
    return post({
        url: '/webcad/orderskplist',
        data
    })
}

// 开具发票 -> 查看详情
export function apiOrderSeeDetail(data) {
    return post({
        url: '/webcad/getinvoice',
        data
    })
}

// 开具发票 -> 开具发票
export function apiUserInvoice(data) {
    return post({
        url: '/webcad/invoicekp',
        data
    })
}

// 判断订单是否为 360 订单
export function apiJudgeOrderType(data) {
    return post({
        url: '/Pcpayorder/orderType',
        data
    })
}

// === 我的精品图库 ===
// 图库状态
export function apiGetGalleryInfo(data) {
    return post({
        url: '/webcad/gallerystatus',
        data
    })
}

// 购买记录
export function apiGetGalleryRecordList(data) {
    return post({
        url: '/webcad/getgallerylist',
        data
    })
}

// 开具发票 -> 获取列表
export function apiGetGalleryOrderInfo(data) {
    return post({
        url: '/webcad/galleryorderinfo',
        data
    })
}

// === 批量购买 ===
// 我的授权码
export function apiGetMyCdkList(data) {
    return post({
        url: '/webcad/getmycdklist',
        data
    })
}

// 我的授权码 -> 修改账户或名称
export function apiUpdateCdKey(data) {
    return post({
        url: '/webcad/updateCDKey',
        data
    })
}

// 激活授权码
export function apiConsumeCdKey(data) {
    return post({
        url: '/webcad/consumecdkey',
        data
    })
}

// 账号解锁 -> 验证验证码
export function apiCheckUnfreezeTwoCode(data) {
    return post({
        url: '/webcad/codeverificationnew',
        data
    })
}

// 账号解锁 -> 验证新密码
export function apiUnfreezeCheckNewPwd(data) {
    return post({
        url: '/webcad/rpcheck',
        data
    })
}

// 账号解锁(微信二维码解冻) -> 获取微信二维码
export function apiGetWxUCode(data) {
    return post({
        url: '/webcad/getwxucode',
        data
    })
}

// 账号解锁(微信二维码解冻) -> 检查扫描状态
export function apiGetWxStatus(data) {
    return post({
        url: '/webcad/getWxstatu',
        data
    })
}

// === 订单信息 ===
// 获取订单信息
export function apiGetOrderDetail(data) {
    return post({
        url: '/Account/orderList',
        data
    })
}

// 获取账号列表
export function apiGetAccountLists(data) {
    return post({
        url: '/Account/accountList',
        data
    })
}

// 修改账号
export function apiChangeAccount(data) {
    return post({
        url: '/Account/updatePhone',
        data
    })
}

// 更新基本信息
export function apiChangeInfo(data) {
    return post({
        url: '/Account/updateInfo',
        data
    })
}

// 删除账号
export function apiDelAccount(data) {
    return post({
        url: '/Account/delAccount',
        data
    })
}

// 添加账号
export function apiAddNewAccount(data) {
    return post({
        url: '/Account/addAccount',
        data
    })
}

// 根据账号判断 订单信息 按钮是否展示
export function apiJudgeHadOrderDetail(data) {
    return post({
        url: '/Account/userInfo',
        data
    })
}