// myCDkey 说明模块
export default {
    namespaced: true,
    state () {
        return {
            // 用户是否看过说明
            cad_know_explain: false,
            company_know_explain: false,
            wisdomyl_know_explain: false,
        }
    },
    mutations: {
        setCadKnowExplain(state, payload) {
            state.cad_know_explain = payload;
        },
        setCompanyKnowExplain(state, payload) {
            state.company_know_explain = payload;
        },
        setWisdomylKnowExplain(state, payload) {
            state.wisdomyl_know_explain = payload;
        },
    }
}