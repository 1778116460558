import { get, post } from '@/api/server';

// 支付宝支付二维码
export function apiAlipayQrcode(data) {
    return post({
        url: '/webwisdomy/enterprisePayAlipay',
        data
    })
}

// 微信支付二维码
export function apiWxpayQrcode(data) {
    return post({
        url: '/webwisdomy/enterprisePayWxpay',
        data
    })
}

// 支付检查
export function apiPayCheck(data) {
    return post({
        url: '/cad/paycheck',
        data
    })
}
/* --- 个人中心 结束 --- */