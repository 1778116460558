import { createStore } from 'vuex'
import createPersistedState from 'vuex-persistedstate'
// vuex-persistedstate默认使用localstorage存储，可以在浏览器的Application里面找到存储的信息
import SecureLS from "secure-ls";
import tool from '@/assets/js/tool';
 
// modules里面声明的模块
import user from './modules/user';
import realm from './modules/realm';
import cdkey from './modules/cdkey';
import cad from './modules/web/cad';
import company from './modules/web/company';
import wisdomyl from './modules/web/wisdomyl';
import maple from './modules/web/maple';
import activity from './modules/web/activity';

var ls = new SecureLS({
  encodingType: "aes",    //加密类型
  isCompression: false,   
  encryptionSecret: "encryption",   //PBKDF2值
});

export default createStore({
  state: {
    LOGIN_FORM: '',
    user_info_time: '',                    // 用户上次登录的时间
    userInfo: '',                          // 用户信息
    isLogin: false,                        // 是否登录
    has_vip: false,                        // 是否拥有 vip
    other_open: {
      sdvip: false,
    },                                     // 网页新加的 vip 开通选项
  },
  getters: {
    getLoginForm(state) {
      state.LOGIN_FORM = localStorage.getItem('LOGIN_FORM');
      return state.LOGIN_FORM;
    },
    getUserInfoTime(state) {
      state.user_info_time = tool.getCookie('USER_INFO_TIME');
      return state.user_info_time;
    }
  },
  mutations: {
    // 用什么方式进行的登录
    setLoginForm(state, payload) {
      state.LOGIN_FORM = payload;
      localStorage.setItem('LOGIN_FORM', payload);
    },
    // 用户上次登录的时间
    setUserInfoTime(state, payload) {
      state.user_info_time = payload;
      tool.setCookie('USER_INFO_TIME', payload);
    },
    // 修改用户信息
    setUserInfo(state, payload) {
      state.userInfo = payload;

      // 是否拥有 vip
      let vip_info = state.userInfo.vip_info;
      if (vip_info) {

        let vip = vip_info.vip;       // 会员
        /* === 额外的 vip start === */
        let mini3d = vip_info.mini3d; // 迷你 3d 会员
        let svip = vip_info.svip;     // 迷你CAD超级会员
        let qyvip = vip_info.qiyevip  // 企业会员
        /* === 额外的 vip end === */
        
        if (vip && vip.code == 1) {
          state.has_vip = true;
        } else if (svip && svip.code == 1) {
          state.has_vip = true;
        } else if (qyvip && qyvip.code === 1) {
          state.has_vip = true;
        } else {
          state.has_vip = false;
        }

        if (mini3d && mini3d.code == 1) {
          state.other_open.sdvip = true;
        } else {
          state.other_open.sdvip = false;
        }
      }
    },
    // 是否有用户信息
    setIsLogin(state, payload) {
      state.isLogin = payload;
    }
  },
  actions: {
  },
  modules: {
    user,
    cdkey,
    cad,
    company,
    wisdomyl,
    maple,
    realm,
    activity
  },
  // 配置安装的vuex-persistedstate插件
  plugins: [
    createPersistedState({
      // 存储名字
      key: 'web_info',
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
      // 指定模块
      paths: ['user', 'maple', 'cdkey']
    })
  ]
})
