import { get, post, formPost } from '@/api/server';

// 获取其他学生信息
export function apiOtherStudentInfo(data) {
    return post({
        url: '/maple/get_child_info_new',
        data
    })
}

// 上传学生证图片
export function apiUpLoadFlair(data) {
    return formPost({
        url: '/oss/upload_maple',
        data
    })
}

// 上传主账号学生信息
export function apiPostStudentSelf(data) {
    return post({
        url: '/maple/submit_user_info_new',
        data
    })
}

// 上传其他学生信息
export function apiPostOtherStudent(data) {
    return post({
        url: '/maple/submit_child_info_new',
        data
    })
}