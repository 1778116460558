import { get, post } from '@/api/server';

// 自动登录
export function apiBimUseTokenLogin(data) {
    return post({
        url: '/webbim/autologin', 
        data
    })
}

// 检查是否有购买记录栏目
export function apiCheckUserId(data) {
    return post({
        url: '/webbim/check_user_id', 
        data
    })
}

// 账号&密码登录
export function apiAccountPwdLogin(data) {
    return post({
        url: '/webbim/loginaccount', 
        data
    })
}

// 手机验证码登录
export function apiPhoneCodeLogin(data) {
    return post({
        url: '/webbim/loginsendmsg', 
        data
    })
}

// 微信登录获取二维码
export function apiWeChatLoginGetCode(data) {
    return get({
        url: '/wechat/get_wxlogin_code', 
        data
    })
}

// 微信登录获取二维码状态
export function apiGetWxCodeStatus(data) {
    return get({
        url: '/wechat/get_wxlogin_status', 
        data
    })
}

// 微信登录扫码成功后回调
export function apiScanWxCodeBack(data) {
    return post({
        url: '/webbim/pcnewloginweixin', 
        data
    })
}

// 微信绑定手机号
export function apiWxBindTelphone(data) {
    return post({
        url: '/webbim/wxbindphone', 
        data
    })
}

// 注册
export function apiToRegister(data) {
    return post({
        url: '/webbim/rgister', 
        data
    })
}
