import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import './assets/less/public.less'

import svg from './assets/icons/index'

// 设置移动端最小视口宽度
function changeMeta() {
    let winW = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    if (winW <= 980) {
        let metaEle = document.querySelector('[name="viewport"]');
        metaEle.content = 'width=980';
    } else {
        let metaEle = document.querySelector('[name="viewport"]');
        metaEle.content = 'width=device-width,initial-scale=1.0';
    }
}
changeMeta();

const app = createApp(App);
app.use(store)
    .use(router)
    .use(ElementPlus)
    .use(svg)
    .mount('#app')