const requireAll = requireContext => requireContext.keys().map(requireContext)
 
import Svgicon from '@/components/svgIcon/SvgIcon.vue'
// 如果不需要深层查找则修改为false即可
const req = require.context('./svg', true, /\.svg$/)
export default {
  install(app) {
    requireAll(req);
    app.component('svg-icon', Svgicon)   // 挂载在全局
  }
}