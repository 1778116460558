// 用户模块
export default {
    namespaced: true,
    state () {
        return {
            user_name: '',
            token: '',
            offline_code: {     // 离线码
                cad: '',
                company: '',
                wisdomyl: '',
                bim: ''
            }
        }
    },
    mutations: {
        // 用户
        setUserName(state, payload) {
            state.user_name = payload;
        },
        // token
        setUserToken(state, payload) {
            state.token = payload;
        },
        // 离线码
        setOfflineCode(state, payload) {
            if (payload.name == 'cad') {
                state.offline_code.cad = payload.code;
            } else if (payload.name == 'company') {
                state.offline_code.company = payload.code;
            } else if (payload.name == 'wisdomyl') {
                state.offline_code.wisdomyl = payload.code;
            } else if (payload.name == 'bim') {
                state.offline_code.bim = payload.code;
            }
        },
        // 清除该模块所有数据
        setClearAll(state) {
            state.user_name = '';
            state.token = '';
            if (state.offline_code.cad) state.offline_code.cad = '';
            if (state.offline_code.company) state.offline_code.company = '';
            if (state.offline_code.wisdomyl) state.offline_code.wisdomyl = '';
            if (state.offline_code.bim) state.offline_code.bim = '';
        }
    }
}