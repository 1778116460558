<template>
  <!-- 将父组件的事件传递到自己身上 并传递给子组件 -->
  <svg
    :class="svgClass"
    :width="width || size"
    :height="height || size"
    aria-hidden="true"
    v-on="$attrs"
  >
    <use :xlink:href="iconName" />
  </svg>
</template>
<script>
export default {
  name: 'SvgIcon',
  /**
   * @description 组件传参
   * props:
   *   name: svg 的名称
   *   className: 给 svg 设置单独的类名
   */
  props: {
    name: {
      type: String,
      required: true,
    },
    className: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: '18px',
    },
    width: {
      type: String,
      default: '',
    },
    height: {
      type: String,
      default: '',
    },
  },
  computed: {
    iconName() {
      return `#icon-${this.name}`;
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className;
      } else {
        return 'svg-icon';
      }
    },
  },
};
</script>
<style scoped>
.svg-icon {
  fill: inherit;
}
</style>
