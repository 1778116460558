// 域名模块
export default {
    namespaced: true,
    state () {
        return {
            baseURL: 'https://app.olcad.com',      // 项目域名
            interfaceURL: 'https://app.pcw365.com',// 项目接口域名
            linkURL1: 'https://www.olcad.com',     // 官网首页域名
            linkURL2: 'https://www.52cad.com',     // 在线看图域名
        }
    },
}