import axios from 'axios' // 引用axios
import { ElMessage } from 'element-plus';
const qs = require('qs'); // 引入 axios 中的 qs ,用于转 post 请求格式

// axios 配置
axios.defaults.baseURL = process.env.VUE_APP_BASE_API; // 线上接口
axios.defaults.timeout = 30000;                        // 设置接口响应延迟时间
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';

// 请求拦截
axios.interceptors.request.use(config => {
    // console.log(config); // config是一个包含了所有请求信息的对象 在这里可以修改config对象 修改之后需要返回config对象 请求才会正常进行
    return config
}, err => {
    Promise.reject(err)
})
 
// 响应拦截
axios.interceptors.response.use(res => {
    // res 是服务器返回的数据信息
    // console.log(res);
    return res.data
}, err => {
    ElMessage({  message: '服务器异常，请稍后再试', type: 'error', showClose: true, duration: 2000 })
    throw new Error(err)
})

// 封装get请求
export function get(obj) {
    return new Promise((resolve, reject) => {
        axios.get(obj.url, {
            params: obj.data
        }).then(res => {
            resolve(res)
        }).catch(err => {
            reject(err)
        })
    })
}

// 封装post请求
export function post(obj) {
    return new Promise((resolve, reject) => {
        axios.post(obj.url, qs.stringify(obj.data)).then(res => {
            resolve(res)
        }).then(err => {
            reject(err)
        })
    })
}

// 封装参数为formData的 post 请求
export function formPost(obj) {
    return new Promise((resolve, reject) => {
        axios.post(obj.url, obj.data, {
            timeout: obj.timeout // 设置单独的timeout时间
        }).then(res => {
            resolve(res)
        }).then(err => {
            reject(err)
        })
    })
}