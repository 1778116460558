// 活动页面
export default {
  namespaced: true,
  state () {
    return {
      check_pay: {
        timer: '',
        times: 0,

        timer2: '',
        times2: 0,
      },  // 检查用户是否扫码及扫码后是否支付的定时器
    }
  },
}