import { get, post } from '@/api/server'

export function apiGetPrice(data) {
  return post({
    url: '/webcad/getprice',
    data,
  })
}

// 获取订单号
export function apiGetOrdersNum(data) {
  return post({
    url: '/webcad/getordersnum',
    data,
  })
}

// 支付宝支付二维码
export function apiAlipayQrcode(data) {
  return post({
    url: '/chargeweb/alipayqrcode',
    data,
  })
}

// 微信支付二维码
export function apiWxpayQrcode(data) {
  return post({
    url: '/chargeweb/wxpay',
    data,
  })
}

// 支付检查
export function apiPayCheck(data) {
  return post({
    url: '/cad/paycheck',
    data,
  })
}

// 图库会员支付宝支付二维码
export function apiGalleryAlipay(data) {
  return post({
    url: '/galleryvipnew/alipay',
    data,
  })
}

// 图库会员微信支付二维码
export function apiGalleryWxpay(data) {
  return post({
    url: '/galleryvipnew/wxpay',
    data,
  })
}

// 图库会员支付检查
export function apiGalleryPayCheck(data) {
  return post({
    url: '/galleryvipnew/pay_stat',
    data,
  })
}

// * 二码合一
// 获取支付码
export function apiGetPayCode(data) {
  return post({
    url: '/pcpay/Getpaycode',
    data,
  })
}

// 查询扫码状态
export function apiGetPayState(data) {
  return post({
    url: '/cad/checkcode',
    data,
  })
}
/* --- 个人中心 结束 --- */
