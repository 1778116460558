import CryptoJS from 'crypto-js'

/* 功能函数 */
// 添加过渡动画
function animateToTop(obj, target, speed) {
  obj.timer = setInterval(function() {
    var step = (target - obj.pageYOffset) / 10;
    step = step > 0 ? Math.ceil(step) : Math.floor(step);
    if (obj.pageYOffset == target) {
      clearInterval(obj.timer);
    } else {
      obj.scroll(0, obj.pageYOffset + step);
    }
  }, speed) 
}

function rotationAnimate(obj, target, callback, time) {
  var ani_time = time || 20; // 缓动的时间
  clearInterval(obj.timer);
  obj.timer = setInterval(function() {
    var step = (target - obj.offsetLeft) / 10;
    step = step > 0 ? Math.ceil(step) : Math.floor(step);
    if (obj.offsetLeft == target) {
      clearInterval(obj.timer);
      callback && callback()
    } else {
      obj.style.left = obj.offsetLeft + step + 'px';
    }
  },ani_time)
}

// 函数节流
function throttle(fn, interval) {
  var enterTime = 0; //触发的时间
  var gapTime = interval || 1500; //间隔时间，如果interval不传值，默认为1500ms
  return function() {
    var that = this;
    var backTime = new Date(); //第一次函数return即触发的时间
    if(backTime - enterTime > gapTime) {
      fn.apply(that, arguments);
      enterTime = backTime; //赋值给第一次触发的时间 保存第二次触发时间
    }
  };
}

// 函数防抖
function debounce(fn, interval) {
  var timer;
  var gapTime = interval || 1000; //间隔时间 不传值默认为1000ms
  return function() {
    clearTimeout(timer);
    var that = this;
    var args = arguments; //保存arguments setTimeout是全局的 arguments不是防抖函数需要的
    timer = setTimeout(function() {
	  fn.apply(that, args);
    }, gapTime);
  };
}

// === 正则 start ===
// 正则判断手机号码是否合法
function isTelephone(value) { 
	if(!(/^1[3456789]\d{9}$/.test(value))) { 
		return false; 
	} else {
		return true;
	}
}

// 正则判断邮箱格式是否合法
function checkEmail(word) {
  let email  = /^([a-zA-Z0-9]+[_|\-|\.]?)*[a-zA-Z0-9]+@([a-zA-Z0-9]+[_|\-|\.]?)*[a-zA-Z0-9]+\.[a-zA-Z]{2,3}$/;

  if (email.test(word)) {
    return true
  } else {
    return false
  }
}

// 正则判断是否含空格
function includeSpace(value) {
	let reg = /\s+/g;
	if (reg.test(value)) {
		return true;
	} else {
		return false;
	}
}

// 正则判断是否为整数
function isIntegerNumber(value) {
  let reg = /^[0-9]\d*$/g;
  if (reg.test(value)) {
		return true;
	} else {
		return false;
	}
}

function inputNumberOnly(value) {
  return value = value.replace(/[^0-9]/g, '')
}
// === 正则 end ===

// 刷新网页
function reloadWeb() {
  window.location.reload();
}

// 隐藏手机号码中间字段
function setHidePhone(phone) {
	var tel = phone;
	tel = "" + tel;
	var ary = tel.split("");
	ary.splice(3, 5, "****");
	var tel1 = ary.join("");
	return tel1
}

// === 时间 start ===
// 时间格式补零
function timeAddZero(num) {
	if (num < 10) {
		return '0' + num;
	} else {
		return num;
	}
}

function getNowTime(format) {
	let nowTime = new Date();// 获取当前时间
  let getNowTimeTxt;
  if (format) {  // 获取当前时间 0000-00-00 00:00:00（格式化）
    getNowTimeTxt = nowTime.getFullYear() + '-' + timeAddZero(nowTime.getMonth() + 1) + '-' + timeAddZero(nowTime.getDate()) + ' ' + timeAddZero(nowTime.getHours()) + ':' + timeAddZero(nowTime.getMinutes()) + ':' + timeAddZero(nowTime.getSeconds());// 时间
  } else {       // 获取当前时间 00000000000000 (未格式化)
    getNowTimeTxt = nowTime.getFullYear() + '' + timeAddZero(nowTime.getMonth() + 1) + '' + timeAddZero(nowTime.getDate()) + '' + timeAddZero(nowTime.getHours()) + '' + timeAddZero(nowTime.getMinutes()) + '' + timeAddZero(nowTime.getSeconds());// 时间
  }

	return getNowTimeTxt;
}

// 格式化 10 位的时间戳 ( use_txt: 是否用文字拼接 )
function formatTenTime(timer, use_txt) {
  let time = new Date(timer * 1000);
  let getTimeTxt;
  if (use_txt) {
    getTimeTxt = time.getFullYear() + '年' + timeAddZero(time.getMonth() + 1) + '月' + timeAddZero(time.getDate()) + '日' + timeAddZero(time.getHours()) + '时' + timeAddZero(time.getMinutes()) + '' + timeAddZero(time.getSeconds());
  } else {
    getTimeTxt = time.getFullYear() + '-' + timeAddZero(time.getMonth() + 1) + '-' + timeAddZero(time.getDate()) + ' ' + timeAddZero(time.getHours()) + ':' + timeAddZero(time.getMinutes()) + ':' + timeAddZero(time.getSeconds());// 时间
  }

  return getTimeTxt;
}
// === 时间 end ===

// === cookies start ===
// 设置cookies
function setCookie(name, value, time) {
  var exp = new Date();
  if (time) {
    exp.setTime(exp.getTime() + time * 1000);
    document.cookie = name + "=" + escape(value) + ";expires=" + exp.toGMTString() + ";path=/";
  } else {
    document.cookie = name + "=" + escape(value) + ";path=/";
  }
}

// 读取cookies
function getCookie(name) {
  var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
  if (arr = document.cookie.match(reg)) {
    return unescape(arr[2]);
  } else {
    return null;
  }
}

// 删除cookies
function delCookie(name) {
  var exp = new Date();
  exp.setTime(exp.getTime() - 1);
  var cval = getCookie(name);
  if (cval != null)
    document.cookie = name + "=" + cval + ";expires=" + exp.toGMTString();
}
// === cookies end ===

const secretKey = 'olpersonalcenter' // 16 位密钥，用于 AES-128 加密
function encryptText(text) {
  const key = CryptoJS.enc.Utf8.parse(secretKey)
  const iv = CryptoJS.enc.Utf8.parse(secretKey)
  const encrypted = CryptoJS.AES.encrypt(text, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  const encryptedBase64 = CryptoJS.enc.Base64.stringify(encrypted.ciphertext)
  return encodeURIComponent(encryptedBase64) // Handle URL encoding issues
}

// Decrypt function
function decryptText(ciphertext) {
  const decodedCiphertext = decodeURIComponent(ciphertext) // Handle URL encoding issues
  const key = CryptoJS.enc.Utf8.parse(secretKey)
  const iv = CryptoJS.enc.Utf8.parse(secretKey)
  const encryptedHexStr = CryptoJS.enc.Base64.parse(decodedCiphertext)
  const encrypted = CryptoJS.lib.CipherParams.create({
    ciphertext: encryptedHexStr,
  })
  const decrypted = CryptoJS.AES.decrypt(encrypted, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7,
  })
  return decrypted.toString(CryptoJS.enc.Utf8)
}

/*导出*/
export default {
  animateToTop,
  throttle,
  debounce,
  isTelephone,
  checkEmail,
  includeSpace,
  isIntegerNumber,
  reloadWeb,
  setHidePhone,
  getNowTime,
  formatTenTime,
  setCookie,
  getCookie,
  delCookie,
  rotationAnimate,
  encryptText,
  decryptText,
  inputNumberOnly
}