import { get, post } from '@/api/server';

// 获取订单号
export function apiGetOrdersNum(data) {
    return post({
        url: '/maple/get_a_osn',
        data
    })
}

// 支付宝支付二维码
export function apiAlipayQrcode(data) {
    return post({
        url: '/maple/alipayqrcode',
        data
    })
}

// 微信支付二维码
export function apiWxpayQrcode(data) {
    return post({
        url: '/maple/wxpay',
        data
    })
}

// 支付检查
export function apiPayCheck(data) {
    return post({
        url: '/maple/paycheck',
        data
    })
}