import { get, post } from '@/api/server';

// 获取用户信息
export function apiGetUserInfo(data) {
    return post({
        url: '/maple/getinfo', 
        data
    })
}

// 获取验证码
export function apiGetEmailCode(data) {
    return post({
        url: '/maple/sendcode', 
        data
    })
}

// 注册
export function apiToRegister(data) {
    return post({
        url: '/maple/submit_reg', 
        data
    })
}

// 登录
export function apiToLogin(data) {
    return post({
        url: '/maple/loginnew', 
        data
    })
}
