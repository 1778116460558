// 数学工具软件-Maple中文学生模块
import tool from "@/assets/js/tool";
export default {
    namespaced: true,
    state () {
        return {
            maple_login_time: '',// 用户上次登录的时间
            maple_user_info: '', // 用户信息
        }
    },
    getters: {
        getMapleLoginTime(state) {
            state.maple_login_time = tool.getCookie('Maple_Login_Time');
            return state.maple_login_time;
        },
    },
    mutations: {
        // 修改用户上次登录的时间
        setMapleLoginTime(state, payload) {
            state.maple_login_time = payload;
            tool.setCookie('Maple_Login_Time', payload);
        },
        setMapleUserInfo(state, payload) {
            state.maple_user_info = payload;
        },
        clearThisAll(state, payload) {
            state.maple_account = '';
            state.maple_login_time = '';
            state.maple_user_info = '';
        }
    }
}