// cad 企业版模块
export default {
    namespaced: true,
    state () {
        return {
            isUserId: false,        // 检查是否有购买记录栏目
            showCertificate: false, // 检查是否有授权证书栏目
        }
    },
    mutations: {
        setUserId(state, payload) {
            state.isUserId = payload;
        },
        setShowCertificate(state, payload) {
            state.showCertificate = payload;
        }
    }
}